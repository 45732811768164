const Loyalty = {
  STATUS_CUSTOMER: {
    ERROR_CUSTOMER_NOTFOUND: "ERROR_CUSTOMER_NOTFOUND",
    CUSTOMER_ALREADY: "CUSTOMER_ALREADY",
    CUSTOMER_ACTIVATED: "CUSTOMER_ACTIVATED",
    CUSTOMER_UNACTIVATED: "CUSTOMER_UNACTIVATED",
    CUSTOMER_NOTFOUND: "CUSTOMER_NOTFOUND",
    CUSTOMER_ALREADY_CREATED: "CUSTOMER_ALREADY_CREATED",
    ERROR_PHONE_EMPTY: "ERROR_PHONE_EMPTY",
    ERROR_SEND_OTP: "ERROR_SEND_OTP",
    ERROR_ADD_NEW_USER: "ERROR_ADD_NEW_USER",
    ERROR_OTP_ALREADY: "ERROR_OTP_ALREADY",
    CUSTOMER_UNACTIVATED_FORGOT: "CUSTOMER_UNACTIVATED_FORGOT",
  },
  VERIFY: {
    CUSTOMER_ACTIVED: "CUSTOMER_ACTIVED",
    CUSTOMER_ALREADY: "CUSTOMER_ALREADY",
    CUSTOMER_NOTFOUND: "CUSTOMER_NOTFOUND",
    ERROR_CUSTOMER_NOTFOUND: "ERROR_CUSTOMER_NOTFOUND",
    ERROR_OTP_INVALID: "ERROR_OTP_INVALID",
    ERROR_CUSTOMER_ACTIVATED: "ERROR_CUSTOMER_ACTIVATED",
    ERROR_ACTIVATED_CUSTOMER: "ERROR_ACTIVATED_CUSTOMER",
    ERROR_DESTINATION_VERIFIED: "ERROR_DESTINATION_VERIFIED",
    ERROR_VERIFIED_DESTINATION: "ERROR_VERIFIED_DESTINATION",
    ERROR_OTP_ALREADY: "ERROR_OTP_ALREADY",
    TYPE_CUSTOMER_ACTIVE: 1, // xác thực tài khoản
    TYPE_CUSTOMER_CREATEPASSWORD: 2, // tạo password cho KH
    TYPE_CUSTOMER_FORGOTPASSWORD: 3, // quên mật khẩu
    TYPE_CUSTOMER_CHANGECONTACT: 10, //thay đổi contact
  },
  ERRORS: {
    ERROR_ACCOUNT_INVALID: "error_account_invalid",
    ERROR_PASSWORD_REQUIRED: "error_password_required",
    ERROR_PHONE_INVALID: "error_phone_invalid",
    ERROR_OVER_LIMIT: "ERROR_OVER_LIMIT",
    ERROR_CUSTOMER_INVALID: "error_customer_invalid",
    ERROR_PHONE_EXISTS: "error_phone_exists",
    ERROR_EMAIL_EXISTS: "error_email_exists",
    ERROR_CUSTOMER_NOT_FOUND: "error_customer_notfound",
    ERROR_EMAIL_OR_PHONE_REQUIRED: "error_email_or_phone_required",
    ERROR_OTP_ALREADY: "ERROR_OTP_ALREADY",
    ERROR_CUSTOMER_UNACTIVATED: "error_customer_unactivated",
    ERROR_TYPE_OVER_STEP_FORGOT: "ERROR_TYPE_OVER_STEP_FORGOT",
    CUSTOMER_UNACTIVATED: "CUSTOMER_UNACTIVATED",
    ERROR_TYPE_OVER_STEP: "ERROR_TYPE_OVER_STEP",
    ERROR_DESTINATION_FORGOT_EMPTY: "ERROR_DESTINATION_FORGOT_EMPTY",
    ERROR_DESTINATION_CREATE_EMPTY: "ERROR_DESTINATION_CREATE_EMPTY",
    ERROR_OTP_EMPTY: "ERROR_OTP_EMPTY",
    ERROR_OTP_INVALID: "ERROR_OTP_INVALID",
    //
    ERROR_JWT_HASH_NOT_FOUND: "error_jwt_hash_not_found",
    //
    ERROR_GET_SETTIING: "error_get_setting",
  },
  VERIFY_LOCK: {
    QUOTA: 5,
    USED: 1,
  },
};

export default Loyalty;
