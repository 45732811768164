"use client";
import ArrowLeftIcon from "@/components/icons/ArrowLeftIcon";
import React from "react";
import { useRouter } from "next/navigation";
type Props = {
  title: string;
  navigateCustom?: () => void;
  showArrow?: boolean;
};
const AuthTitle = ({ title, navigateCustom, showArrow = true }: Props) => {
  const router = useRouter();
  return (
    <div className="flex justify-center items-center mb-4 gap-1">
      <span
        className="cursor-pointer"
        onClick={() => {
          if (navigateCustom) {
            navigateCustom();
          } else {
            router.back();
          }
        }}
      >
        {showArrow && <ArrowLeftIcon />}
      </span>
      <p className="font-medium text-gray-500 text-h4 text-center text-xl">{title}</p>
    </div>
  );
};

export default AuthTitle;
