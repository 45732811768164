"use client";
import moment from "moment";
import React, { useState, useEffect } from "react";

type Props = {
  initialSeconds: number;
  format?: "HH:mm:ss" | "mm:ss" | "HH:mm";
  onFinish?: any;
  className?: string;
  classNameWrapper?: string;
};
//
function CountdownOTP({
  initialSeconds,
  format,
  onFinish,
  className,
  classNameWrapper,
}: Props) {
  const [timeRemaining, setTimeRemaining] = useState(initialSeconds);
  console.log("🥺 ~ timeRemaining:", timeRemaining);

  useEffect(() => {
    setTimeRemaining(initialSeconds);
  }, [initialSeconds]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeRemaining > 0) {
        setTimeRemaining((prevTime) => prevTime - 1);
      } else {
        clearInterval(interval);
        if (onFinish && initialSeconds !== 0) {
          onFinish();
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining, onFinish]);

  const minutes = Math.floor((timeRemaining % 3600) / 60);
  const seconds = timeRemaining % 60;
  const hours = Math.floor(timeRemaining / 3600);

  const renderFormat = () => {
    switch (format) {
      case "HH:mm:ss":
        return (
          <>
            {hours < 10 ? "0" : ""}
            {hours}:{minutes < 10 ? "0" : ""}
            {minutes}:{seconds < 10 ? "0" : ""}
            {seconds}
          </>
        );
      case "HH:mm":
        return (
          <>
            {hours < 10 ? "0" : ""}
            {hours}:{minutes < 10 ? "0" : ""}
            {minutes}
          </>
        );
      case "mm:ss":
        return (
          <>
            {minutes < 10 ? "0" : ""}
            {minutes}:{seconds < 10 ? "0" : ""}
            {seconds}
          </>
        );
      default:
        return (
          <>
            {minutes < 10 ? "0" : ""}
            {minutes}:{seconds < 10 ? "0" : ""}
            {seconds}
          </>
        );
    }
  };

  return (
    <div className={classNameWrapper}>
      <p className={className}>{renderFormat()}</p>
    </div>
  );
}

export default CountdownOTP;
