"use client";
import { useController, FieldValues, FieldPath } from "react-hook-form";
import React from "react";
import { PropsController } from "@/types/Component.type";
import ErrorControl from "./components/ErrorControl";
import { cn } from "@/utils/utils";

export type InputCoreProps<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
> = React.InputHTMLAttributes<HTMLInputElement> &
	PropsController<TFieldValues, TName> & {
		refinput?: React.Ref<HTMLInputElement> | null;
		showError?: boolean;
		errorClassName?: string;
		className?: string;
		inputClassName?: string;
	};

function InputCore<
	TFieldValues extends FieldValues,
	TName extends FieldPath<TFieldValues>
>(props: InputCoreProps<TFieldValues, TName>) {
	const {
		control,
		name,
		rules,
		onBlur,
		onChange,
		refinput,
		showError = true,
		errorClassName,
		className,
		inputClassName,
		...res
	} = props;
	const { field, fieldState } = useController({
		name,
		control,
		rules,
	});

	return (
		<>
			<div className={cn("relative", className)}>
				<input
					className={inputClassName}
					{...field}
					{...res}
					disabled={props.disabled}
					type={res.type}
					// remove behavior required defauil
					required={false}
					ref={refinput}
					onChange={(event) => {
						if (res.type === "number") {
							const lastValue =
								event.target.value[event.target.value.length - 1];
							if (/[0-9]/.test(lastValue) || event.target.value.length === 0) {
								field.onChange(event.target.value);
								onChange?.(event);
							}
							return;
						}

						field.onChange(event.target.value);
						onChange?.(event);
					}}
					onBlur={(event) => {
						field.onBlur();
						onBlur?.(event);
					}}
					onKeyDown={(e) => {
						e.stopPropagation();
					}}
				/>
				{/* {res.type === "password" && (
          <span
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              togglePasswordVisibility();
            }}
            className="px-2 absolute inset-y-0 right-0 flex items-center  text-sm hover:cursor-pointer  focus:outline-none"
          >
      
            {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
          </span>
        )} */}
			</div>
			<ErrorControl
				className={errorClassName}
				error={fieldState.error}
				showError={showError}
			/>
		</>
	);
}
export default InputCore;
