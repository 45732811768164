"use client";

import CustomerConstants from "@/common/constants/customer";
import Loyalty from "@/common/constants/loyalty";
import useTranslationApi from "@/lib/hooks/useTranslationApi";
import { useMemo } from "react";

const NTVerifyOTPHeader = ({
  type,
  status,
  used,
  quota,
  seconds,
  account_id,
  isResend,
  isSubmit,
  error,
  tax = "",
}: {
  type: string;
  status: string;
  used: number;
  quota: number;
  seconds: number;
  account_id: string;
  isResend: boolean;
  isSubmit: boolean;
  error: string[];
  tax: string;
}) => {
  const { t } = useTranslationApi([
    "customer_ERROR_OTP_INVALID",
    "error_required",
    "error_otp_required",
    "customer_ERROR_OVER_LIMIT",
    "customer_error_get_setting",
    "customer_api_network_error",
    "verify_otp_loading",
    "submit_at_zero_second",
    "tax_title_1",
    "no_tax_title_1",
    "tax_title_3",
    "tax_title_2",
    "tax_phone",
    "veifyheader_change_info",
    "verifyheader_newsletter",
  ]);

  const getSubTitle = () => {
    if (error.length > 0) {
      if (error.includes("ERROR_OVER_LIMIT")) {
        return (
          <p className="text-sm text-red-500">
            {t("customer_ERROR_OVER_LIMIT")}
          </p>
        );
      } else {
        return (
          <p className="text-sm text-red-500">
            {t("customer_api_network_error")}
          </p>
        );
      }
    } else {
      //Thiết lập mật khẩu (khách cũ)
      if (
        type === JSON.stringify(Loyalty.VERIFY.TYPE_CUSTOMER_CREATEPASSWORD)
      ) {
        if (seconds > 0 && !isResend && !isSubmit) {
          return (
            <div>
              <>
                {tax.length > 0 ? (
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                ) : (
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                )}
              </>
            </div>
          );
        } else if (seconds <= 0 && !isResend && !isSubmit) {
          return (
            <div>
              <>
                {tax.length > 0 ? (
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                ) : (
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                )}
              </>
            </div>
          );
        } else if (isResend) {
          if (used === quota && seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("customer_ERROR_OVER_LIMIT")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        } else if (isSubmit) {
          if (seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("submit_at_zero_second") + " " + t("is_establist_pw2")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        }
      } else if (type === JSON.stringify(Loyalty.VERIFY.TYPE_CUSTOMER_ACTIVE)) {
        ///Active sau khi đăng ký
        if (seconds > 0 && !isResend && !isSubmit) {
          return (
            <div>
              <>
                {tax.length > 0 ? (
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_2") + " " + t("register_heading")}.
                  </p>
                ) : (
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_2") + " " + t("register_heading")}.
                  </p>
                )}
              </>
            </div>
          );
        } else if (seconds <= 0 && !isResend && !isSubmit) {
          return (
            <div>
              <>
                {tax.length > 0 ? (
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                ) : (
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_3") + " " + t("is_establist_pw2")}.
                  </p>
                )}
              </>
            </div>
          );
        } else if (isResend) {
          if (used === quota && seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("customer_ERROR_OVER_LIMIT")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        } else if (isSubmit) {
          if (seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("submit_at_zero_second") + " " + t("register_heading")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        }
      } else if (
        type === JSON.stringify(Loyalty.VERIFY.TYPE_CUSTOMER_FORGOTPASSWORD)
      ) {
        ///Lấy lại mật khẩu, khách quên pass
        if (seconds > 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <p>
                  {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                  {t("tax_title_3") + " " + t("forgot_password_heading")}
                </p>
              ) : (
                <p>
                  {t("no_tax_title_1")}{" "}
                  {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                  <span className="font-bold">{account_id}</span>.{" "}
                  {t("tax_title_3") + " " + t("forgot_password_heading")}.
                </p>
              )}
            </div>
          );
        } else if (seconds <= 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <p>
                  {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                  {t("tax_title_3") + " " + t("forgot_password_heading")}
                </p>
              ) : (
                <p>
                  {t("no_tax_title_1")}{" "}
                  {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                  <span className="font-bold">{account_id}</span>.{" "}
                  {t("tax_title_3") + " " + t("forgot_password_heading")}
                </p>
              )}
            </div>
          );
        } else if (isResend) {
          if (used === quota && seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("customer_ERROR_OVER_LIMIT")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        } else if (isSubmit) {
          if (seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("submit_at_zero_second") +
                  " " +
                  t("forgot_password_heading")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        }
      } else if (
        type === JSON.stringify(CustomerConstants.SENDER_TYPE.CHANGE_CONTACT) ||
        type === JSON.stringify(CustomerConstants.SENDER_TYPE.VERIFY)
      ) {
        ///Thay đổi email
        if (seconds > 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <p>
                  {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                  {t("tax_title_2") + " " + t("veifyheader_change_info")}.
                </p>
              ) : (
                <p>
                  {t("no_tax_title_1")}{" "}
                  {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                  <span className="font-bold">{account_id}</span>.{" "}
                  {t("tax_title_2") + " " + t("veifyheader_change_info")}.
                </p>
              )}
            </div>
          );
        } else if (seconds <= 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <p>
                  {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                  {t("tax_title_3") + " " + t("veifyheader_change_info")}.
                </p>
              ) : (
                <p>
                  {t("no_tax_title_1")}{" "}
                  {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                  <span className="font-bold">{account_id}</span>.{" "}
                  {t("tax_title_3") + " " + t("veifyheader_change_info")}.
                </p>
              )}
            </div>
          );
        } else if (isResend) {
          if (used === quota && seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("customer_ERROR_OVER_LIMIT")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        } else if (isSubmit) {
          if (seconds <= 0) {
            return (
              <p className="text-sm text-red-500">
                {t("submit_at_zero_second") +
                  " " +
                  t("veifyheader_change_info")}
              </p>
            );
          } else {
            return (
              <div>
                <p className="text-sm text-gray-500">
                  {t("verify_otp_loading")}
                </p>
              </div>
            );
          }
        }
      } else if (type === "6") {
        ///Đăng ký nhận tin
        if (seconds > 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <>
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_2") + " " + t("verifyheader_newsletter")}.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_2") + " " + t("verifyheader_newsletter")}.
                  </p>
                </>
              )}
            </div>
          );
        } else if (seconds <= 0 && !isResend && !isSubmit) {
          return (
            <div>
              {tax.length > 0 ? (
                <>
                  <p>
                    {t("tax_title_1")} <span className="font-bold">{tax}</span>.
                    {t("tax_title_2") + " " + t("verifyheader_newsletter")}.
                  </p>{" "}
                </>
              ) : (
                <>
                  <p>
                    {t("no_tax_title_1")}{" "}
                    {account_id.includes("@") ? "email" : t("tax_phone")}{" "}
                    <span className="font-bold">{account_id}</span>.{" "}
                    {t("tax_title_2") + " " + t("verifyheader_newsletter")}.
                  </p>
                </>
              )}
            </div>
          );
        } else if (isResend) {
          if (used === quota && seconds <= 0) {
            return (
              <>
                <p className="text-sm text-red-500">
                  {t("customer_ERROR_OVER_LIMIT")}
                </p>
              </>
            );
          } else {
            return (
              <div>
                <p> {t("verify_otp_loading")}</p>
              </div>
            );
          }
        } else if (isSubmit) {
          if (seconds <= 0) {
            return (
              <>
                <p className="text-sm text-red-500">
                  {t("submit_at_zero_second") +
                    " " +
                    t("verifyheader_newsletter")}
                </p>
              </>
            );
          } else {
            return (
              <div>
                <p> {t("verify_otp_loading")}</p>
              </div>
            );
          }
        }
      }
    }
    return <></>;
  };

  const SubTitle = useMemo(() => {
    return getSubTitle();
  }, [seconds, isResend, isSubmit, error]);

  return (
    <div className="verify_header_wrapper">
      <div className="flex justify-center items-center">
        <div className="text-center mb-[40px]">{SubTitle}</div>
      </div>
    </div>
  );
};

export default NTVerifyOTPHeader;
