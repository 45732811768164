import React from "react";
import { cn } from "@/utils/utils";

function LabelControl(
	props: React.LabelHTMLAttributes<HTMLLabelElement> & { isrequired?: boolean }
) {
	const { isrequired, className, ...res } = props;
	return (
		<label
			{...res}
			className={cn("text-sm leading-4 text-gray-400 ", className)}>
			{props.children}
			{isrequired && <span className=" text-red-500"> *</span>}
		</label>
	);
}

export default LabelControl;
