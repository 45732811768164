import { cn } from "@/utils/utils";
import React from "react";
import { FieldError } from "react-hook-form";

interface ErrorControlProps {
	error: FieldError | undefined;
	showError?: boolean;
	className?: string;
}
function ErrorControl({ error, showError, className = "" }: ErrorControlProps) {
	return (
		<>
			{error?.message && showError ? (
				<p className={cn("text-sm text-red-500 mt-1", className)}>
					{error.message}{" "}
				</p>
			) : null}
		</>
	);
}

export default ErrorControl;
